import {Form} from "antd";
import React, { useEffect } from "react";

const WrappedForm = (props) => {

    const [form] = Form.useForm();
    //props.init && props.init(form);

    useEffect(() => {
        props.init && props.init(form);
        //form.resetFields();
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [props.initialValues]);



    //scrollToFirstError={true}
    return  <Form
                form={form}
                onFinish={props.onSubmit || function () {}}
                scrollToFirstError={true}
                initialValues={props.initialValues || {}}
                name={props.name}
                layout={props.layout || 'vertical'}
            >
                {props.children}
            </Form>
};

export default WrappedForm;