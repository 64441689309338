import {Form} from "antd";
import React from "react";

const WrappedShouldUpdate = (props) => {
    return  <Form.Item
fieldKey={props.fieldKey}
hasFeedback={props.hasFeedback}
                shouldUpdate={true}
                noStyle={true}
            >
                {props.children}
            </Form.Item>
};

export default WrappedShouldUpdate;