import React from "react";
import {Form, Upload} from "antd";
import { BASE_URL, API_VERSION } from "../../middleware/api";
import {useSelector} from "react-redux";
import { InboxOutlined } from '@ant-design/icons';

const normFile = e => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};


const WrappedSimpleUpload = (props) => {

    const {
        idToken
    } = useSelector(state => ({
        idToken: state.AuthReducer.idToken
    }));

    let action;
    let name;

    if(Array.isArray(props.name)) {
        name = props.name;
        name[1] = name[1]+"_UPLOAD";
    } else {
        name = props.name+"_UPLOAD";
    }

    if(props.action) {
        action = props.action;
    } else {
        action = BASE_URL+'api/'+API_VERSION+"/files/upload"
    }


    return  <React.Fragment>
                <Form.Item
fieldKey={props.fieldKey}
hasFeedback={props.hasFeedback}
                    label={props.label}
                    name={name}
                    rules={props.rules}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle={false}
                >
                    <Upload

                        action={action}
                        headers={{authorization: `Bearer ${idToken}`}}
                        accept={props.accept}
                        listType={props.listType || 'picture'}
                        multiple={props.multiple || false}
                        showUploadList={true}
                        disabled={props.disabled}

                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Upload>
                </Form.Item>
            </React.Fragment>
};

export default WrappedSimpleUpload;