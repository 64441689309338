import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './styles/css/index.css';
import configureStore from './store/configureStore';
import Index from './containers';
import history from './history';


const store = configureStore();
const rootElement = document.getElementById('root');


ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Index />
        </Router>
    </Provider>, rootElement);