import { CALL_API } from '../../middleware/api';


const actions = {
    LOAD_JSX_REQUEST: 'LOAD_JSX_REQUEST',
    LOAD_JSX_SUCCESS: 'LOAD_JSX_SUCCESS',
    LOAD_JSX_ERROR: 'LOAD_JSX_ERROR',

    LOAD_JSX_DATA_REQUEST: 'LOAD_JSX_DATA_REQUEST',
    LOAD_JSX_DATA_SUCCESS: 'LOAD_JSX_DATA_SUCCESS',
    LOAD_JSX_DATA_ERROR: 'LOAD_JSX_DATA_ERROR',

    SAVE_JSX_DATA_REQUEST: 'SAVE_JSX_DATA_REQUEST',
    SAVE_JSX_DATA_SUCCESS: 'SAVE_JSX_DATA_SUCCESS',
    SAVE_JSX_DATA_ERROR: 'SAVE_JSX_DATA_ERROR',

    LOAD_DATA_REQUEST: 'LOAD_DATA_REQUEST',
    LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
    LOAD_DATA_ERROR: 'LOAD_DATA_ERROR',

    CLEAR_JSX: 'CLEAR_JSX',
    CLEAR_DATA: 'CLEAR_DATA'
};

export default actions;


export function loadJSX(app, view, identifier) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'apps/'+app+'/view/'+view,
            reducerPayload: {app, view, identifier},
            types: [actions.LOAD_JSX_REQUEST, actions.LOAD_JSX_SUCCESS, actions.LOAD_JSX_ERROR]
        }
    };
}

export function clearJSX(identifier) {
    return {
        type: actions.CLEAR_JSX,
        identifier
    }
}


export function loadJSXData(params = {}) {
    //postData: {fields: params.fields},
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'apps/'+params.app+'/'+params.endpoint + (params.name ? '/'+params.name : '') + (params.id ? '/'+params.id : '') + (params.fields ? '?fields='+encodeURI(params.fields) : ''),

            reducerPayload: {app: params.app, name: params.name, identifier: params.identifier},
            types: [actions.LOAD_JSX_DATA_REQUEST, actions.LOAD_JSX_DATA_SUCCESS, actions.LOAD_JSX_DATA_ERROR]
        }
    };
}

export function loadData(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'apps/'+params.app+'/'+params.endpoint + (params.name ? '/'+params.name : '')  + (params.id ? '/'+params.id : ''),
            reducerPayload: {app: params.app, name: params.name, identifier: params.identifier},
            types: [actions.LOAD_DATA_REQUEST, actions.LOAD_DATA_SUCCESS, actions.LOAD_DATA_ERROR]
        }
    };
}


export function clearData(params) {
    return {
        type: actions.CLEAR_DATA,
        identifier: params.identifier,
        app: params.app
    }
}

export function saveJSXData(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'apps/'+params.app+'/'+params.endpoint + (params.name ? '/'+params.name : '')  + (params.id ? '/'+params.id : ''),
            postData: {fields: params.fields},
            reducerPayload: {app: params.app, name: params.name, identifier: params.identifier},
            types: [actions.SAVE_JSX_DATA_REQUEST, actions.SAVE_JSX_DATA_SUCCESS, actions.SAVE_JSX_DATA_ERROR]
        }
    };
}

