import React from 'react';
import NotFound from './404';
import JSXView from './JSXView';
import { Switch, Route } from 'react-router-dom';
import Bluetrax from "../components/Bluetrax";
import moment from "moment";
import '../styles/css/App.css';

const Index = () => {

    if (window.React === undefined) {
        window.React = React;
    }
    if (window.Bluetrax === undefined) {
        window.Bluetrax = Bluetrax;
    }
    if (window.moment === undefined) {
        window.moment = moment;
    }

    return (
        <Switch>
            <Route path='/:APP/:VIEW?' component={JSXView}/>
            <Route path='*' component={NotFound}/>
        </Switch>
    );
}

export default Index;
