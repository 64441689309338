import notification from '../components/Notification';
import history from '../history';

//DEV LOCALHOST
//export let BASE_URL = "http://localhost:80/";

//DEV WITH STAGING API
//export let BASE_URL = "http://staging.bluetrax.local:81/";

//PRODUCTION & STAGING
export let BASE_URL = window.location.protocol+"//"+window.location.hostname+":"+window.location.port+"/";


export let API_VERSION = "v1";

export function callApi(endpoint, type, data = {},  raw = false) {

    let config = {};

    let url = BASE_URL + 'api/' + API_VERSION + "/" ;



    if(type === 'GET' || type === 'DELETE') {
        config = {
            method: type,
            headers: { 'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 },
            cache: "no-cache"
        };
    } else if(type === 'POST' || type === 'PUT') {
        config = {
            method: type,
            headers: { 'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 },
            cache: "no-cache",
            body: JSON.stringify(data)
        };
    }

    if(raw) {
        return fetch(url + endpoint, config)
            .then(function(response) {
                if (!response.ok) {
                    return Promise.reject(response);
                }
                return response;
            }).catch(err => {
                return Promise.reject(err);
            });
    }

    return fetch(url + endpoint, config)
        .then(function(response) {
            if (!response.ok) {
                return Promise.reject(response);
            } else {
                return response.json();
            }

        }).then(function(response) {
            if(response.alert && response.alert.message) {
                if(response.alert.duration !== null && response.alert.duration !== undefined) {
                    notification(response.alert.type, response.alert.message, response.alert.description, response.alert.duration);
                } else {
                    notification(response.alert.type, response.alert.message);
                }
            }
            if(typeof(response.redirect) !== 'undefined' && response.redirect) {
                history.push(response.redirect);
            }
            return response;
        }).catch(err => {
            return Promise.reject(err);
        });
}


export const CALL_API = Symbol('Call API');

export default store => next => action => {

    const callAPI = action[CALL_API];




    // So the middleware doesn't get applied to every single action
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { endpoint, types, reqType, postData, raw, reducerPayload } = callAPI;

    const [ requestType, successType, errorType ] = types;


    next({
        type: requestType,
        reducerPayload
    });

    return callApi(endpoint ,reqType, postData, raw).then(

        response =>
            next({
                response,
                type: successType,
                reducerPayload
            }),
        function(error) {

            if (error.status === 500 || error.status === 400 || error.status === 401) {

                error.json().then(result => {


                    if (result.alert && result.alert.message) {
                        notification(result.alert.type, result.alert.message);
                    }
                    next({
                        type: errorType,
                        reducerPayload
                    });

                });


            } else {
                notification('error', "an unknown error occoured");
                next({
                    type: errorType,
                    reducerPayload
                });
            }
        }
    );
};



