import {combineReducers} from 'redux';
import JSXReducer from './JSX/reducer';
import SelectboxReducer from './Selectbox/reducer';


const rootReducer = combineReducers({
   JSXReducer,
   SelectboxReducer
});

export default rootReducer;