import React from "react";
import { PageHeader } from 'antd';

/*
const WrappedSection = (props) => {
    return  <div
                className={""}
            >
                {props.children}
            </div>
};
 */

const WrappedSection = (props) => {
    return  <PageHeader
                className="bluetrax-section"
                backIcon={false}
                title={props.title}
                subTitle={props.subTitle}
            >
                {props.children}
            </PageHeader>
};

export default WrappedSection;