import React from "react";
import {Version} from "../../helpers/Version";

const WrappedVersionTag = (props) => {
    return  <div className={"bluetrax-info-footer"}>
                <span>
                    <a target="_blank" href={"https://www.bluetrax-software.de"}>Bluetrax</a> - Software by IndivCoding
                    <br/>
                    <span className={"bluetrax-version"}>v{Version}</span>
                </span>
            </div>
};

export default WrappedVersionTag;