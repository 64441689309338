import {Form, Button} from "antd";
import React from "react";

const WrappedButton = (props) => {
    return  <Form.Item
fieldKey={props.fieldKey}
hasFeedback={props.hasFeedback}>
                <Button
                    onClick={props.onClick}
                    htmlType={props.htmlType}
                    type={props.type}
                    icon={props.icon}
                    loading={props.loading}
                    block={props.block}
                    disabled={props.disabled}
                >
                    {props.children}
                </Button>
            </Form.Item>
};

export default WrappedButton;