import { CALL_API } from '../../middleware/api';


const actions = {


    LOAD_SELECT_DATA_REQUEST: 'LOAD_SELECT_DATA_REQUEST',
    LOAD_SELECT_DATA_SUCCESS: 'LOAD_SELECT_DATA_SUCCESS',
    LOAD_SELECT_DATA_ERROR: 'LOAD_SELECT_DATA_ERROR',

    SET_SELECT_DATA: 'SET_SELECT_DATA',

    CLEAR_SELECT_DATA: 'CLEAR_SELECT_DATA'
};

export default actions;


export function loadSelectData(params = {}) {

    let endpoint = 'apps/'+params.app+'/list/'+params.name+'/select';

    if(params.search) {
        endpoint += '/?search='+encodeURI(params.search);
    }

    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint,
            reducerPayload: params,
            types: [actions.LOAD_SELECT_DATA_REQUEST, actions.LOAD_SELECT_DATA_SUCCESS, actions.LOAD_SELECT_DATA_ERROR]
        }
    };
}


export function setSelectData(params = {}) {
    return {
        type: actions.SET_SELECT_DATA,
        params
    };
}




export function clearSelectData(identifier) {
    return {
        type: actions.CLEAR_SELECT_DATA,
        identifier
    };
}