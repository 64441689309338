import {Radio} from "antd";
import React from "react";

const WrappedRadio = (props) => {
    return <Radio
                onChange={props.onChange}
                value={props.value}
            >
                {props.children}
            </Radio>
};

export default WrappedRadio;