import React from 'react';


const WrappedActionFooter = (props) => {
    return   <div
        className={"actionFooter"}
    >
        {props.children}
    </div>
};

export default WrappedActionFooter;