import {Form, Input} from "antd";
import React from "react";

const { TextArea } = Input;

const WrappedTextarea = (props) => {
    return  <Form.Item
fieldKey={props.fieldKey}
hasFeedback={props.hasFeedback}
                label={props.label}
                name={props.name}
                rules={props.rules}
                noStyle={props.type === "hidden"}
            >
                <TextArea
                    placeholder={props.placeholder}
                    hasFeedback={props.hasFeedback || false}
                    addonBefore={props.addonBefore}
                    addonAfter={props.addonAfter}
                    disabled={props.disabled}
                    rows={props.rows || 4}
                />
            </Form.Item>
};

export default WrappedTextarea;