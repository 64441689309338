import React, { useState } from "react";
import {Form, Upload} from "antd";
import { BASE_URL, API_VERSION } from "../../middleware/api";
import { InboxOutlined } from '@ant-design/icons';
import notification from '../Notification';

const WrappedUpload = (props) => {

    let action;

    if(props.action) {
        action = props.action;
    } else {

        action = BASE_URL+'api/'+API_VERSION+"/files/upload"
    }


    const normFile = e => {
        console.log(e);
        if (Array.isArray(e)) {
            return e;
        }
        if(props.multiple) {
            return e && e.fileList;
        } else {
            return e && e.fileList.slice(-1);
        }
    };

    const beforeUpload = (file) => {
        const fileSizeMB = file.size / 1024 / 1024;
        const maxFileSize = props?.maxFileSize ? props.maxFileSize : 10;
        if (fileSizeMB > maxFileSize) {
            notification('error', 'Ihre Datei überschreitet die maximale Größe von '+maxFileSize+'MB');
            return Promise.reject();
        }
        return Promise.resolve();
    }


    //valuePropName="fileList"
    return  <React.Fragment>

                <Form.Item
                    hasFeedback={props.hasFeedback}
                    label={props.label}
                    name={Array.isArray(props.name) ? [props.name[0],(props.name[1]+"_UPLOAD")] : props.name+"_UPLOAD"}
                    rules={props.rules}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle={false}
                >
                    <Upload.Dragger

                        action={action}
                        accept={props.accept}
                        listType={props.listType || 'picture'}
                        multiple={props.multiple || false}
                        showUploadList={true}
                        disabled={props.disabled}
                        beforeUpload={beforeUpload}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klicken oder ziehen Sie Dateien in diesen Bereich um diese hochzuladen.</p>
                        <p className="ant-upload-hint">
                            {props.multiple ? 'Sie können mehrere Dateien hochladen.' : 'Sie können nur eine Datei hochladen.'}<br/>
                            {props.accept ? 'Folgende Dateiformate werden akzeptiert: '+props.accept.replace(/,/g, ', ') : ''}
                        </p>
                    </Upload.Dragger>
                </Form.Item>
            </React.Fragment>
};

export default WrappedUpload;