import actions from './actions';

const initState =
    {
        selectData: [],
        selectDataLoading: []
    };


export default function SelectboxReducer(state = initState, action) {
    switch (action.type) {

        case actions.CLEAR_SELECT_DATA:
            return Object.assign({}, state, {
                selectData: Object.assign({}, state.selectData,{[action.identifier] : []}),
                selectDataLoading: Object.assign({}, state.selectDataLoading,{[action.identifier] : false})
            });

        case actions.SET_SELECT_DATA:
            return Object.assign({}, state, {
                selectData: Object.assign({}, state.selectData,{[action.params.identifier] : action.params.data}),
                selectDataLoading: Object.assign({}, state.selectDataLoading,{[action.params.identifier] : false})
            });

        case actions.LOAD_SELECT_DATA_REQUEST:
            return Object.assign({}, state, {
                selectData: Object.assign({}, state.selectData,{[action.reducerPayload.identifier] : []}),
                selectDataLoading: Object.assign({}, state.selectDataLoading,{[action.reducerPayload.identifier] : true})
            });
        case actions.LOAD_SELECT_DATA_SUCCESS:
            return Object.assign({}, state, {
                selectData: Object.assign({}, state.selectData,{[action.reducerPayload.identifier] : action.response.data}),
                selectDataLoading: Object.assign({}, state.selectDataLoading,{[action.reducerPayload.identifier] : false})
            });
        case actions.LOAD_SELECT_DATA_ERROR:
            return Object.assign({}, state, {
                selectData: Object.assign({}, state.selectData,{[action.reducerPayload.identifier] : []}),
                selectDataLoading: Object.assign({}, state.selectDataLoading,{[action.reducerPayload.identifier] : false})
            });
        default:
            return state;
    }
}
