import {Form, DatePicker} from "antd";
import React from "react";


const DatePickerLocale = {
    "lang": {
        "locale": "de_DE",
        "placeholder": "Datum auswählen",
        "rangePlaceholder": ["Datum von", "Datum bis"],
        "today": "Heute",
        "now": "Jetzt",
        "backToToday": "Zurück zu heute",
        "ok": "Übernehmen",
        "clear": "Löschen",
        "month": "Monat",
        "year": "Jahr",
        "timeSelect": "Zeit auswählen",
        "dateSelect": "Datum auswählen",
        "monthSelect": "Monat auswählen",
        "yearSelect": "Jahr auswählen",
        "decadeSelect": "Jahrzehnt auswählen",
        "yearFormat": "YYYY",
        "dateFormat": "D.M.YYYY",
        "dayFormat": "D",
        "dateTimeFormat": "D.M.YYYY HH:mm:ss",
        "monthFormat": "MMMM",
        "monthBeforeYear": true,
        "previousMonth": "Vorheriger Monat",
        "nextMonth": "Nächster Monat",
        "previousYear": "Letztes Jahr",
        "nextYear": "Nächstes Jahr",
        "previousDecade": "Letztes Jahrzehnt",
        "nextDecade": "Nächstes Jahrzehnt",
        "previousCentury": "Letztes Jahrhundert",
        "nextCentury": "Nächstes Jahrhundert"
    },
    "timePickerLocale": {
        "placeholder": "Zeit auswählen"
    },
    "dateFormat": "DD.MM.YYYY",
    "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
    "weekFormat": "wo YYYY",
    "monthFormat": "MM.YYYY"
};

const WrappedDatePicker = (props) => {
    return  <Form.Item
                fieldKey={props.fieldKey}
                hasFeedback={props.hasFeedback}
                label={props.label}
                name={Array.isArray(props.name) ? [props.name[0],(props.name[1]+"_DATE")] : props.name+"_DATE"}
                rules={props.rules}
                initialValue={props.initialValue}
            >
                <DatePicker
                    placeholder={props.placeholder}
                    style={{width: '100%'}}
                    disabled={props.disabled}
                    showTime={props.showTime}
                    locale={DatePickerLocale}
                    getPopupContainer={trigger => trigger.parentNode}
                    format={props.format || (props.showTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY")}
                />
            </Form.Item>
};

export default WrappedDatePicker;