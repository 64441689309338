import {Tooltip} from "antd";
import React from "react";
import Icon from './Icon';

const WrappedIconTooltip = (props) => {
    return  <Tooltip {...props} >
        &nbsp;<Icon type={props.icon} />&nbsp;
    </Tooltip>
};

export default WrappedIconTooltip;