import {Col} from "antd";
import React from "react";

const WrappedCol = (props) => {
    return  <Col
                span={props.span}
                xs={props.xs}
                sm={props.sm}
                md={props.md}
                lg={props.lg}
                xl={props.xl}
                xxl={props.xxl}
                flex={props.flex}
                offset={props.offset}
                order={props.order}
                pull={props.pull}
                push={props.push}
            >
                {props.children}
            </Col>
};

export default WrappedCol;