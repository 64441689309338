import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as JSXActions from "../../redux/JSX/actions";

const WrappedFetchData = (props) => {

    let identifier = props.app+'_'+props.endpoint+'_'+props.name;

    const dispatch = useDispatch();


    useEffect(() => {
        if(props.app && props.name) {
            dispatch(JSXActions.loadData({identifier, app: props.app,  endpoint: props.endpoint, name: props.name, id: props.id}));
        }

        return () => dispatch(JSXActions.clearData({identifier, app: props.app}));
    }, []);



    return  null;
};

export default WrappedFetchData;