import {Form, InputNumber} from "antd";
import React from "react";

const WrappedInputNumber = (props) => {
    return  <Form.Item
                fieldKey={props.fieldKey}
                label={props.label}
                name={props.name}
                rules={props.rules}
                hasFeedback={props.hasFeedback || false}
                noStyle={props.type === "hidden"}
            >
                <InputNumber
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    min={props.min}
                    max={props.max}
                    precision={props.precision}
                    formatter={props.formatter}
                    parser={props.parser}
                    decimalSeparator={props.decimalSeparator}
                    step={props.step}
                    style={{width: '100%'}}
                />
            </Form.Item>
};

export default WrappedInputNumber;