import {Radio, Form} from "antd";
import React from "react";

const WrappedRadioGroup = (props) => {
    return  <Form.Item
                fieldKey={props.fieldKey}
                hasFeedback={props.hasFeedback}
                label={props.label}
                name={props.name}
                onChange={props.onChange}
                rules={props.rules}
            >
                <Radio.Group disabled={props.disabled}>
                    {props.radio?.map(option => <Radio key={option.value} value={option.value}>{option.text}</Radio>)}
                    {props.children}
                </Radio.Group>
            </Form.Item>
};

export default WrappedRadioGroup;