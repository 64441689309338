import {Modal} from "antd";
import React, {useState, useEffect} from "react";

const WrappedModal = (props) => {

    const [visible, setVisible] = useState(false);
    const [toggleMount, setToggleMount] = useState(false);

    const [sharedVariables, setSharedVariables] = useState({lolRoflxD: "hello"});

    useEffect(() => {
        setVisible(props.visible);
    },[props.visible]);

    useEffect(() => {
        if(toggleMount) {
            setVisible(!visible);
        } else {
            setToggleMount(true);
        }
    },[props.toggle]);


    const onClose = () => {
        props.onClose && props.onClose();
        setVisible(false);
    };

    const onOk = () => {
        props.onOk && props.onOk();
        if(!props.noAutoHide) {
            setVisible(false);
        }
    };


    return  <Modal
                title={props.title}
                closable={props.closable}
                height={props.height}
                width={props.width}
                onCancel={onClose}
                onOk={onOk}
                okText={props.okText || "Ok"}
                cancelText={props.cancelText || "Schließen"}
                destroyOnClose={props.destroyOnClose || true}
                visible={visible}
            >
                {props.children}
            </Modal>
};

export default WrappedModal;