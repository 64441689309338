import actions from './actions';
import { BASE_URL, API_VERSION } from "../../middleware/api";
import moment from 'moment';

const initState =
    {
        JSXViewCode: [],
        loadingJSXViewCode: [],
        JSXData: [],
        loadingJSXData: [],
        data: [],
        loadingData: [],
        savingJSXData: [],
        savingJSXDataError: []
    };


export default function JSXReducer(state = initState, action) {
    switch (action.type) {

        case actions.LOAD_JSX_REQUEST:
            return Object.assign({}, state, {
                JSXViewCode: Object.assign({}, state.JSXViewCode, {[action.reducerPayload.identifier]: ""}),
                loadingJSXViewCode: Object.assign({}, state.loadingJSXViewCode, {[action.reducerPayload.identifier]: true}),
            });
        case actions.LOAD_JSX_SUCCESS:
            return Object.assign({}, state, {
                JSXViewCode: Object.assign({}, state.JSXViewCode, {[action.reducerPayload.identifier]: action.response.data?.jsx}),
                loadingJSXViewCode: Object.assign({}, state.loadingJSXViewCode, {[action.reducerPayload.identifier]: false}),
            });
        case actions.LOAD_JSX_ERROR:
            return Object.assign({}, state, {
                JSXViewCode: Object.assign({}, state.JSXViewCode, {[action.reducerPayload.identifier]: ""}),
                loadingJSXViewCode: Object.assign({}, state.loadingJSXViewCode, {[action.reducerPayload.identifier]: false}),
            });

        case actions.CLEAR_JSX:
            return Object.assign({}, state, {
                JSXViewCode: Object.assign({}, state.JSXViewCode, {[action.identifier]: ""}),
                loadingJSXViewCode: Object.assign({}, state.loadingJSXViewCode, {[action.identifier]: false}),
                JSXData: Object.assign({}, state.JSXData, {[action.identifier]: {}}),
                loadingJSXData: Object.assign({}, state.loadingJSXData, {[action.identifier]: false}),
            });


        case actions.LOAD_JSX_DATA_REQUEST:
            return Object.assign({}, state, {
                JSXData: Object.assign({}, state.JSXData, {[action.reducerPayload.identifier]: {}}),
                loadingJSXData: Object.assign({}, state.loadingJSXData, {[action.reducerPayload.identifier]: true}),
            });
        case actions.LOAD_JSX_DATA_SUCCESS:

            let JSXData = action.response.data;
            Object.keys(JSXData).forEach(JSXDataKey => {
                if (JSXDataKey.includes("_DATE") && JSXData[JSXDataKey]) {
                    JSXData[JSXDataKey] = moment(JSXData[JSXDataKey]);
                }
                if (JSXDataKey.includes("_UPLOAD")) {
                    if (Array.isArray(JSXData[JSXDataKey])) {
                        JSXData[JSXDataKey] = JSXData[JSXDataKey].map(file => {
                            file.thumbUrl = BASE_URL + 'api/' + API_VERSION + file.thumbUrl + ".png";
                            file.url = BASE_URL + 'api/' + API_VERSION + file.url;
                            return file;
                        });
                    }
                }
                if (JSXDataKey.includes("_FILE")) {
                    JSXData[JSXDataKey] = BASE_URL + 'api/' + API_VERSION + JSXData[JSXDataKey];
                }
            });

            return Object.assign({}, state, {
                JSXData: Object.assign({}, state.JSXData, {[action.reducerPayload.identifier]: JSXData}),
                loadingJSXData: Object.assign({}, state.loadingJSXData, {[action.reducerPayload.identifier]: false}),
            });
        case actions.LOAD_JSX_DATA_ERROR:
            return Object.assign({}, state, {
                JSXData: Object.assign({}, state.JSXData, {[action.reducerPayload.identifier]: ""}),
                loadingJSXData: Object.assign({}, state.loadingJSXData, {[action.reducerPayload.identifier]: false}),
            });


        case actions.SAVE_JSX_DATA_REQUEST:
            return Object.assign({}, state, {
                savingJSXData: Object.assign({}, state.savingJSXData, {[action.reducerPayload.identifier]: true}),
                savingJSXDataError: Object.assign({}, state.savingJSXDataError, {[action.reducerPayload.identifier]: false}),
            });
        case actions.SAVE_JSX_DATA_SUCCESS:
            return Object.assign({}, state, {
                savingJSXData: Object.assign({}, state.savingJSXData, {[action.reducerPayload.identifier]: false}),
                savingJSXDataError: Object.assign({}, state.savingJSXDataError, {[action.reducerPayload.identifier]: false}),
            });
        case actions.SAVE_JSX_DATA_ERROR:
            return Object.assign({}, state, {
                savingJSXData: Object.assign({}, state.savingJSXData, {[action.reducerPayload.identifier]: false}),
                savingJSXDataError: Object.assign({}, state.savingJSXDataError, {[action.reducerPayload.identifier]: true}),
            });


        case actions.LOAD_DATA_REQUEST:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {[action.reducerPayload.app] :  Object.assign({}, state.data[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: {}})}),
                loadingData: Object.assign({}, state.loadingData, {[action.reducerPayload.app] :  Object.assign({}, state.loadingData[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: true})})
            });
        case actions.LOAD_DATA_SUCCESS:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {[action.reducerPayload.app] :  Object.assign({}, state.data[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: action.response.data})}),
                loadingData: Object.assign({}, state.loadingData, {[action.reducerPayload.app] :  Object.assign({}, state.loadingData[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: false})})

            });
        case actions.LOAD_DATA_ERROR:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {[action.reducerPayload.app] :  Object.assign({}, state.data[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: {}})}),
                loadingData: Object.assign({}, state.loadingData, {[action.reducerPayload.app] :  Object.assign({}, state.loadingData[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: false})})
            });

        case actions.CLEAR_DATA:
            if(action?.reducerPayload?.app && action?.reducerPayload?.identifier) {
                return Object.assign({}, state, {
                    data: Object.assign({}, state.data, {[action.reducerPayload.app] :  Object.assign({}, state.data[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: {}})}),
                    loadingData: Object.assign({}, state.loadingData, {[action.reducerPayload.app] :  Object.assign({}, state.loadingData[action.reducerPayload.app] || {}, {[action.reducerPayload.identifier]: false})})
                });
            } else {
                return state;
            }


        default:
            return state;
    }
}
