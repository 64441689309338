import React from "react";
import Catch from "./functional-error-boundary";


export const ErrorBoundary = Catch(function ErrorBoundary(props, error) {
    if (error) {
        return (
            <div className="error-screen">
                <h2>An error has occured</h2>
                <h4>{error.message}</h4>
            </div>
        )
    } else {
        return <React.Fragment>{props.children}</React.Fragment>
    }
});

export function tryEval(code) {
    return "try { "+code+" } catch(e) {"+'React.createElement("div", null, React.createElement("h1", null, "Error"), React.createElement("span", null, "\\"", e.message ? e.message : "unknown error", "\\""));'+"};"
}

export function tryEvalSave(code) {
    code = saveEval() + " "+code;
    return "try { "+code+" } catch(e) {"+'React.createElement("div", null, React.createElement("h1", null, "Error"), React.createElement("span", null, "\\"", e.message ? e.message : "unknown error", "\\""));'+"};"
}

function saveEval() {
    return " let [state, setState] = React.useState({}); let loadModuleSettings = function() {}; let loadSettings = function() {}; let initSettingsForm = function() {}; let saveSettings = function() {alert('daten werden gespeichert...');}; let changeView = function() {alert('changeView kann hier nicht simuliert werden.');}; let formChangedValid = function() {}; let loadForm = function() {}; let saveForm = function() {}; let initForm = function() {};  let JSXData = {}; let loadingJSXData = false; let savingJSXData = false; let props = {}; let sharedVariables = {}; let moduleFunctionRunning = {}; let moduleFunctionResult = {}; let appFunctionRunning = {}; let appFunctionResult = {}; ";
}
