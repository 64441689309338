import {Form, Input} from "antd";
import React from "react";

const WrappedTextbox = (props) => {
    return  <Form.Item
fieldKey={props.fieldKey}
        label={props.label}
        name={props.name}
        rules={props.rules}
        hasFeedback={props.hasFeedback || false}
        noStyle={props.type === "hidden"}
    >
        <Input
            placeholder={props.placeholder}
            addonBefore={props.addonBefore}
            addonAfter={props.addonAfter}
            disabled={props.disabled}
            type={props.type}
        />
    </Form.Item>
};

export default WrappedTextbox;