import React, { Component } from 'react';
import '../../styles/css/404.css';


const NotFound = () => {
        return (
            <div class="FourZeroFour">
                <div className="FOFerror">
                    <div className="FOFcontainer-floud">
                        <div className="col-xs-12 ground-color text-center">
                            <div className="container-error-404">
                                <div className="clip">
                                    <div className="shadow"><span className="digit thirdDigit">4</span></div>
                                </div>
                                <div className="clip">
                                    <div className="shadow"><span className="digit secondDigit">0</span></div>
                                </div>
                                <div className="clip">
                                    <div className="shadow"><span className="digit firstDigit">4</span></div>
                                </div>
                                <div className="FOFmsg">OH!<span className="triangle"></span></div>
                            </div>
                            <h2 className="FOFh1">Sorry! Diese Seite wurde nicht gefunden.</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
}
export default NotFound;