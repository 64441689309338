import {Table} from "antd";
import React from "react";

const WrappedSimpleTable = (props) => {


    return  <Table
                columns={props.columns}
                dataSource={props.data}
                onRow={(record) => ({
                    onClick: () => {
                        props.onRowClick && props.onRowClick(record);
                    }
                })}
                rowClassName={(record, index) => 'table-clickable'}
            />
};

export default WrappedSimpleTable;