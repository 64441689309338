import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {Form, Spin, Select, Empty} from "antd";
import * as SelectboxActions from '../../redux/Selectbox/actions';

const { Option } = Select;

const WrappedSelectbox = (props) => {

    let identifier = props.app+'_'+props.name;

    const dispatch = useDispatch();

    const {
        selectData,
        selectDataLoading
    } = useSelector(state => ({
        selectData: state.SelectboxReducer.selectData?.[identifier] || [],
        selectDataLoading: state.SelectboxReducer.selectDataLoading?.[identifier] || false
    }), shallowEqual);


    useEffect(() => {
        if(props.app && props.name) {
            dispatch(SelectboxActions.loadSelectData({identifier, app: props.app, name: (props.endpoint ? props.endpoint : props.name)}));
        }

        return () => dispatch(SelectboxActions.clearSelectData(identifier));
    }, []);


    useEffect(() => {
        if(!props.app && props.options && Array.isArray(props.options)) {
            dispatch(SelectboxActions.setSelectData({data: props.options, identifier, name:props.name}));
        }

    }, [props.options]);



    return  <Form.Item
        label={props.label}
        name={props.name}
        rules={props.rules}
    >

        <Select
            showSearch={props.showSearch || true}
            optionFilterProp="children"
            filterOption={(input, option) =>
                option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
            getPopupContainer={trigger => trigger.parentNode}
            prefix={props.icon}
            placeholder={props.placeholder}
            notFoundContent={selectDataLoading ? <Spin size="small" /> : <Empty description={<span>Keine Daten gefunden</span>}/>}
            disabled={props.disabled}
            onChange={props.onChange}
            mode={props.mode}
            tokenSeparators={props.tokenSeparators}
        >
            {selectData?.map(option => <Option key={option.value} value={option.value}>{option.text}</Option>)}
            {props.children}
        </Select>

    </Form.Item>
};

export default WrappedSelectbox;






/*import React from "react";
import {Form, Spin, Select, Empty} from "antd";

const { Option } = Select;

const WrappedSelectbox = (props) => {




    return  <Form.Item
                fieldKey={props.fieldKey}
                hasFeedback={props.hasFeedback}
                label={props.label}
                name={props.name}
                rules={props.rules}
            >

                <Select
                    showSearch={props.showSearch || true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                    getPopupContainer={trigger => trigger.parentNode}
                    prefix={props.icon}
                    placeholder={props.placeholder}
                    notFoundContent={<Empty description={<span>Keine Daten gefunden</span>}/>}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    mode={props.mode}
                    tokenSeparators={props.tokenSeparators}
                >
                    {props.options?.map(option => <Option key={option.value} value={option.value}>{option.text}</Option>)}
                    {props.children}
                </Select>

            </Form.Item>
};

export default WrappedSelectbox;*/