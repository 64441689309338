import React from 'react';
import {Steps, Form} from "antd";
const { Step } = Steps;

const WrappedSteps = (props) => {


    // if name is provided, we auto genereate a hidden input and select current step by value
    if(props.name) {
        return  <React.Fragment>
                    <Form.Item
hasFeedback={props.hasFeedback}
                        name={props.name}
                        noStyle={true}
                    >
                    </Form.Item>
                        <Form.Item
hasFeedback={props.hasFeedback}
                            shouldUpdate={true}
                            noStyle={true}
                        >
                        {() => ( <Steps
                                current={props.form?.getFieldValue ? parseInt(props.form.getFieldValue([props.name])) : 1}
                                direction={props.direction}
                                size={props.size}
                                progressDot={props.progressDot}
                                type={props.type}
                                className={props.className}
                            >
                                {(props.steps && Array.isArray(props.steps)) ? props.steps.map(item => (
                                    <Step
                                        key={item.key ? item.key : item.title}
                                        title={item.title}
                                        subTitle={item.subTitle}
                                        description={item.description}
                                        status={item.status}
                                        icon={item.icon}
                                    />
                                )) : props.children}
                            </Steps>)}
                        </Form.Item>
                </React.Fragment>
    } else {
        return <Steps
            current={props.current}
            direction={props.direction}
            size={props.size}
            progressDot={props.progressDot}
            type={props.type}
            className={props.className}
        >
                    {(props.steps && Array.isArray(props.steps)) ? props.steps.map(item => (
                        <Step
                            key={item.title}
                            title={item.title}
                            subTitle={item.subTitle}
                            description={item.description}
                            status={item.status}
                            icon={item.icon}
                        />
                    )) : props.children}
                </Steps>
    }


};

export default WrappedSteps;