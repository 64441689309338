import {Form, Button, Row, Col} from "antd";
import React, {Children, isValidElement, cloneElement} from "react";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const WrappedExtendableList = (props) => {

    return <Form.List name={props.name}>
        {(fields, { add, remove }) => {
            return (
                <div>
                    {fields.map((field, index) => (
                        <Row>
                            <Col span={23}>
                                <div style={props.table ? {display: 'flex', overflow: 'scroll'} : {}}>

                                    {Children.map(props.children, child => {
                                        // Checking isValidElement is the safe way and avoids a TS error too.
                                        if (isValidElement(child)) {
                                            return <div style={props.table ? { flex: '1', padding: '0px 5px', ...child.props.cellStyle} : {}}>{cloneElement(child, { name: [field.name, child.props.name], fieldKey: [[field.fieldKey, child.props.name]] })}</div>
                                        }

                                        return <div style={{ flex: '1', padding: '0px 5px', ...child.props.cellStyle}}>{child}</div>;
                                    })}

                                </div>
                            </Col>
                            <Col span={1}>
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={props.table ? { margin: '38px 5px' } : {margin: '5px'}}
                                    onClick={() => {
                                        !props.disabled && remove(field.name);
                                    }}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Form.Item>
                        <Button
                            block
                            disabled={props.disabled}
                            onClick={() => {
                                !props.disabled && add();
                            }}
                        >
                            <PlusOutlined /> {props.addText}
                        </Button>
                    </Form.Item>
                </div>
            );
        }}
    </Form.List>;
};

export default WrappedExtendableList;