import React, {useState, useEffect, useRef} from 'react';
import {generatePath} from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as JSXActions from "../../redux/JSX/actions";
import Bluetrax from '../../components/Bluetrax';
import history from '../../history';


const WrappedJSX = (props) => {

    let identifier = props.app+'_'+props.view + (props.id ? ("_"+props.id) : "");
    const match = useRouteMatch();

    const dispatch = useDispatch();
    const [state, setStateVal] = useState({step: 1});
    const [form, setForm] = useState({});
    const [multistepData, _setMultistepData] = useState({});
    const [viewport, setViewport] = useState({});
    const [sharedVariables, setSharedVariables] = useState({ form: {} });

    const setState = (newState) => {
        setStateVal({...state, ...newState});
    };

    const setMultistepData = (newFields) => {
        _setMultistepData({...multistepData, ...newFields});
    };

    const {
        JSXViewCode,
        loadingJSXViewCode,
        JSXData,
        data,
        loadingData,
        loadingJSXData,
        savingJSXData,
        savingJSXDataError
    } = useSelector(state => ({
        JSXViewCode: state.JSXReducer.JSXViewCode?.[identifier] || "",
        loadingJSXViewCode: state.JSXReducer.loadingJSXViewCode?.[identifier] || false,
        JSXData: state.JSXReducer.JSXData?.[identifier] || false,
        data: state.JSXReducer.data || false,
        loadingData: state.JSXReducer.loadingData || false,
        loadingJSXData: state.JSXReducer.loadingJSXData?.[identifier] || false,
        savingJSXData: state.JSXReducer.savingJSXData?.[identifier] || false,
        savingJSXDataError: state.JSXReducer.savingJSXDataError?.[identifier] || false,
    }), shallowEqual);


    useEffect(() => {
        if(identifier) {
            dispatch(JSXActions.loadJSX(props.app, props.view, identifier));
        }
    }, [props.app, props.view]);

    useEffect(() => {

        window.addEventListener("resize", windowResize);
        windowResize();

        return () => {
            dispatch(JSXActions.clearJSX(identifier));
            window.removeEventListener("resize", windowResize);
        }
    }, []);


    useEffect(() => {
        if(props.toggleSave !== undefined)
        {
            if(form && form.submit) {
                form.submit();
            }
        }

    }, [props.toggleSave]);


    const windowResize = () => {
        setViewport({width: window.innerWidth, height: window.innerHeight});
    };


//disabled, because web platform cannot load data (until now)
    /*useEffect(() => {
        if(!savingJSXData && !savingJSXDataError) {
            loadForm(form);
        }
    }, [savingJSXData]);*/

    const changeView = (view, id = null) => {
        let genPath = generatePath(match.path, {VIEW: view, ID: id});
        history.push(genPath);
    };

    //disabled, because web platform cannot load data (until now)
    /*const loadForm = (form) => {
        if(form && typeof form.getFieldsValue !== "undefined") {
            let fields = form.getFieldsValue();
            fields = Object.keys(fields);
            //Object.keys(fields).forEach(s => fields[s] = Object.keys(fields[s]));
            dispatch(JSXActions.loadJSXData({app: props.app, name: props.name, endpoint: props.endpoint || props.view, identifier, fields: fields.length > 0 ? fields : null, id: props.id}));
        }
    };*/

    const saveForm = (values) => {
        values = {...values, ...multistepData};
        dispatch(JSXActions.saveJSXData({app: props.app, name: props.name, endpoint: props.endpoint || props.view, identifier, fields: values, id: props.id}));
        props.onSaved && props.onSaved();
    };

    const formChangedValid = () => {
        let res = false;

        if(typeof form.isFieldsTouched !== "undefined") {
            let touched = form.isFieldsTouched();
            let hasError = form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
            res = touched && !hasError;
        }
         return res;
    };

    const initForm = (actForm) => {
        setForm(actForm);
        //disabled, because web platform cannot load data (until now)
        //loadForm(actForm);
        setSharedVariables({ form: actForm, id: props.id });
    };


    const nextFormStep = () => {
        form && form.validateFields().then(values => {
            setMultistepData(values);
            setState({step: (state.step + 1)});
        }).catch(errorInfo => {
            console.log(errorInfo);
            form.scrollToField(errorInfo.errorFields[0].name, {
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        });
    };

    const previousFormStep = () => {
        if(state.step > 1) {
            setState({step: (state.step - 1)});
        }
    };

    return (
        <Bluetrax.Spin spinning={loadingJSXViewCode}>
            <Bluetrax.ErrorBoundary>
                {eval( Bluetrax.tryEval( JSXViewCode ) )}
            </Bluetrax.ErrorBoundary>
        </Bluetrax.Spin>
    )
};

export default WrappedJSX;