import {Drawer} from "antd";
import React, {useState, useEffect} from "react";

const WrappedDrawer = (props) => {

    const [visible, setVisible] = useState(false);
    const [toggleMount, setToggleMount] = useState(false);

    useEffect(() => {
        setVisible(props.visible);
    },[props.visible]);

    useEffect(() => {
        if(toggleMount) {
            setVisible(!visible);
        } else {
            setToggleMount(true);
        }
    },[props.toggle]);


    const onClose = () => {
        setVisible(false);
    };

    return  <Drawer
                title={props.title}
                placement={props.placement}
                closable={props.closable}
                height={props.height}
                width={props.width}
                onClose={onClose}
                visible={visible}
                closable={props.closable || true}
                destroyOnClose={props.destroyOnClose || true}
            >
                {props.children}
            </Drawer>
};

export default WrappedDrawer;