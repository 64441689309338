import React from 'react';
import {useParams} from 'react-router-dom';
import Bluetrax from '../../components/Bluetrax';

const JSXView = () => {
    const { APP, VIEW } = useParams();

    return (
        <Bluetrax.JSX app={APP} view={VIEW ? VIEW : 'main'}/>
    )
};

export default JSXView;