import {Form, Checkbox} from "antd";
import React from "react";

const WrappedCheckbox = (props) => {
    return  <Form.Item
fieldKey={props.fieldKey}
                hasFeedback={props.hasFeedback}
                name={props.name}
                rules={props.rules}
                dependencies={props.dependencies}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={props.disabled}
                    onChange={props.onChange}
                >
                    {props.label}
                </Checkbox>
            </Form.Item>
};

export default WrappedCheckbox;